<!--
 * @Author: wjc
 * @Date: 2023-11-03 16:59:28
 * @LastEditors: wjc
 * @LastEditTime: 2024-04-08 10:18:43
 * @Description: 
-->
<template>
  <MRow justify="space-between" align="middle" class="headers-container hidden-xs-only">
    <el-col
      :md="12"
      :sm="24"
      :xs="24"
      class="flex! sm:justify-start xs:mb-12px sm:mb-12px md:mb-0px"
    >
      <MLink to="/">
        <span class="flex items-center">
          <img src="/logo.png" class="h-62px mr-12px" />
          <span class="text-32px font-500 font-oblique">
            <span>海南省</span>
            <span class="color-primary">物业管理协会</span>
          </span>
        </span>
      </MLink>
    </el-col>
    <el-col
      :lg="12"
      :md="24"
      :sm="24"
      :xs="24"
      class="flex! items-center justify-end sm:justify-end"
    >
      <div class="search-container">
        <ClientOnly>
          <el-select
            filterable
            remote
            no-data-text="暂无数据"
            placeholder="请输入搜索内容"
            :remote-method="getSearch"
            class="search-input"
            @visible-change="visibleSearchChange"
          >
            <template v-for="item in searchData" :key="item.id">
              <el-option :value="item.id" class="text-overflow max-w-240px">
                <MLink
                  :to="item.jumpLink || `/content/post/${item.id}?m=${item.classId2}`"
                  :target="item.jumpLink ? '_blank' : ''"
                  @click="resetSearch"
                >
                  {{ item.name }}
                </MLink>
              </el-option>
            </template>
          </el-select>
        </ClientOnly>
      </div>
      <!-- <div class="flex items-center">
        <ClientOnly>
          <el-space v-if="!userInfo.id">
            <span class="i-ep-user-filled text-primary-color"></span>
            <span class="cursor-pointer font-s-1.4rem" @click="goLogin">登录</span>
          </el-space>
          <el-space v-else class="outline-nonev font-s-1.4rem">
            <MLink to="/admin" class="text-primary-color w-60px text-overflow">企业管理</MLink>
            <el-space>
              <span class="i-ep-user-filled text-primary-color"></span>
              <el-dropdown trigger="hover">
                <el-space class="outline-none">
                  <span class="font-600 font-s-1.4rem w-80px text-overflow">
                    {{ userInfo.name }}
                  </span>
                  <span class="i-ep-arrow-down"></span>
                </el-space>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="onOpenAccount">账号编辑</el-dropdown-item>
                    <el-dropdown-item @click="onLogout">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-space>
          </el-space>
        </ClientOnly>
      </div> -->
    </el-col>
  </MRow>
</template>

<script setup lang="ts">
  import { useUserStore } from '~/stores/modules/user/user'
  import { useLoginStore } from '~/stores/modules/user/login'
  import { getInfoList } from '~/api/activity/info'
  import { getToken } from '@/utils/auth'
  import type { IInfo } from '~/models/activity'
  import { appName } from '@/constants'

  defineOptions({
    name: 'Headers',
  })

  const loginStore = useLoginStore()
  const userStore = useUserStore()
  const userInfo = ref({})
  const searchData = ref<IInfo[]>([])

  watchEffect(() => {
    if (process.browser) {
      const token = getToken()
      if (token) {
        userInfo.value = userStore.getUser
      } else {
        userInfo.value = {}
      }
    }
  })

  const goLogin = () => {
    navigateTo('/login')
  }

  // 业务变更，不再需要提供注册功能
  // const goRegister = () => {
  //   navigateTo('/register')
  // }

  const getSearch = (query: string) => {
    if (query) {
      getInfoList({
        pageNum: 1,
        pageSize: 8,
        isPage: true,
        name: query,
      }).then((res) => {
        if (res && res.data && res.data.list) {
          searchData.value = res.data.list
        }
      })
    }
  }

  const visibleSearchChange = (v: boolean) => {
    if (!v) {
      resetSearch()
    }
  }

  const resetSearch = () => {
    searchData.value = []
  }

  const onOpenAccount = () => {
    navigateTo({
      path: '/admin/account',
      query: {
        edit: 'true',
      },
    })
  }

  const onLogout = () => {
    ElMessageBox.confirm('<strong>是否退出当前登录！</strong>', '', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '取消',
      cancelButtonText: '确认',
      closeOnClickModal: false,
      showClose: false,
      confirmButtonClass: 'el-button is-plain',
      cancelButtonClass: 'el-button el-button--danger',
    })
      .then((e) => e)
      .catch(() => {
        userInfo.value = {}
        loginStore.logout()
        navigateTo('/')
      })
  }
</script>

<style scoped lang="scss">
  .headers-container {
    @apply w-full h-150px;
    background: url('/images/headers-bg.png') no-repeat;
    background-size: cover;
    background-position: left;
    .search-container {
      @apply relative flex-center h-40px min-w-240px sm:min-w-200px;
      .search-input {
        @apply h-40px rounded-20px w-full min-w-240px;
        border: 2px solid var(--primary-color);
        &::placeholder {
          @apply line-height-14px font-s-14px text-color-place;
        }
        &:focus {
          @apply outline-none;
        }
        :deep(.el-input) {
          @apply h-40px;
        }
        :deep(.el-input__wrapper) {
          @apply bg-transparent;
          box-shadow: none !important;
        }
      }
      .search-btn {
        @apply absolute right-0 flex-center rounded-20px bg-bg-color font-s-14px text-color-whiter w-70px h-40px px-20px cursor-pointer;
        height: calc(100% - 4px);
      }
    }
  }
</style>
